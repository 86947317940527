import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import Layout from '../../layouts';
import DownBlock from '../../components/DownBlock/DownBlock';
import AppRating from '../../components/AppRating';
import SolutionLayout from '../../components/Solutions/SolutionLayout/SolutionsLayout';
import headSrc from '../../assets/images/solutions/bounty/head.png';
import headSrc2x from '../../assets/images/solutions/bounty/head_2x.png';
import Description1 from '../../assets/images/solutions/bounty/description_1.svg';
import Description2 from '../../assets/images/solutions/bounty/description_2.svg';
import Description3 from '../../assets/images/solutions/bounty/description_3.svg';
import Description4 from '../../assets/images/solutions/bounty/description_4.svg';
import block1Src from '../../assets/images/solutions/bounty/block_1.png';
import block1Src2x from '../../assets/images/solutions/bounty/block_1_2x.png';
import block2Src from '../../assets/images/solutions/bounty/block_2.png';
import block2Src2x from '../../assets/images/solutions/bounty/block_2_2x.png';
import block3Src from '../../assets/images/solutions/bounty/block_3.png';
import block3Src2x from '../../assets/images/solutions/bounty/block_3_2x.png';
import block4Src from '../../assets/images/solutions/bounty/block_4.png';
import block4Src2x from '../../assets/images/solutions/bounty/block_4_2x.png';
import block5Src from '../../assets/images/solutions/bounty/block_5.png';
import block5Src2x from '../../assets/images/solutions/bounty/block_5_2x.png';
import ImageContainer from '../../components/ImageContainer/ImageContainer';
import BlockTitle from '../../components/BlockTitle/BlockTitle';
import FaqBounty from '../../components/FaqBounty/FaqBounty';

const BountyPage = (): JSX.Element => {
  const head = {
    title: <Trans>Bounty & Airdrop</Trans>,
    text: (
      <Trans>
        Безопасное присутствие на лаунчпадах Bounty & Airdrop — отличная
        возможность получать новые токены или монеты бесплатно. C Octo Browser
        вы сможете участвовать в большем количестве раздач, увеличить свои шансы
        попадания в вайтлист токенсейлов, а значит — масштабировать свой
        заработок.
      </Trans>
    ),
    images: [
      {
        image: <ImageContainer src={headSrc} srcSet={{ _2x: headSrc2x }} />,
      },
    ],
  };

  const descriptions = [
    {
      icon: <Description1 />,
      title: <Trans>Отпечатки реальных устройств</Trans>,
      text: (
        <Trans>
          Обеспечьте полную анонимность в сети с помощью качественной подмены
          всех отслеживаемых параметров браузерного профиля.
        </Trans>
      ),
    },
    {
      icon: <Description2 />,
      title: <Trans>Проверка анонимности</Trans>,
      text: (
        <Trans>
          Пользуйтесь антидетект браузером, который без ошибок и алертов проходит проверки таких популярных чекеров как Pixelscan, BrowserLeaks, CreepJS, Whoer и IP-API.
        </Trans>
      ),
    },
    {
      icon: <Description3 />,
      title: <Trans>Зашифрованное хранилище</Trans>,
      text: (
        <Trans>
          Все данные виртуальных профилей хранятся раздельно в зашифрованном
          облачном хранилище и надежно защищены от утечек.
        </Trans>
      ),
    },
    {
      icon: <Description4 />,
      title: <Trans>Тестирование уязвимостей</Trans>,
      text: (
        <Trans>
          Команда Octo Browser тщательно тестирует браузер на уязвимости, чтобы
          обеспечить максимальную защиту и конфиденциальность.
        </Trans>
      ),
    },
  ];

  const blocks = {
    items: [
      {
        images: [
          {
            image: (
              <ImageContainer src={block1Src} srcSet={{ _2x: block1Src2x }} />
            ),
          },
        ],
        title: <Trans>Обход ограничений</Trans>,
        text: (
          <Trans>
            Расширьте свои возможности в крипто-проектах с помощью
            мультиаккаунтинга и многократно умножьте шансы попадания вайтлист
            токенсейлов, получения баунти-аирдропов и приобретения минт NFT.
          </Trans>
        ),
      },
      {
        images: [
          {
            image: (
              <ImageContainer src={block2Src} srcSet={{ _2x: block2Src2x }} />
            ),
          },
        ],
        title: <Trans>Автоматизация процессов</Trans>,
        text: (
          <Trans>
            Автоматизируйте выполнение повторяющиxся действий, необходимых для
            получения аирдропа или попадания вайтлист для лотерейных
            предложений.
          </Trans>
        ),
      },
      {
        images: [
          {
            image: (
              <ImageContainer src={block3Src} srcSet={{ _2x: block3Src2x }} />
            ),
          },
        ],
        title: <Trans>Прохождение верификации</Trans>,
        text: (
          <Trans>
            Создавайте дополнительные аккаунты не вызывающие никаких подозрений
            при прохождении KYC-верификации.
          </Trans>
        ),
      },
      {
        images: [
          {
            image: (
              <ImageContainer src={block4Src} srcSet={{ _2x: block4Src2x }} />
            ),
          },
        ],
        title: <Trans>Продвижение криптовалюты</Trans>,
        text: (
          <Trans>
            Используйте преимущества мультиаккаунтинга для продвижения
            собственной монеты или NFT-токена и их популяризации в
            крипто-сообществах.
          </Trans>
        ),
      },
      {
        images: [
          {
            image: (
              <ImageContainer src={block5Src} srcSet={{ _2x: block5Src2x }} />
            ),
          },
        ],
        title: <Trans>Защита от идентификации</Trans>,
        text: (
          <Trans>
            Избегайте любых попыток идентификации, минимизируйте угрозы
            обнаружения и блокировки аккаунтов.
          </Trans>
        ),
      },
    ],
    title: (
      <BlockTitle
        highlightIsBlock
        highlightText={<Trans>Масштабируйте заработок на криптовалютах</Trans>}
        title={<Trans>с Octo Browser</Trans>}
        highlightPosition="start"
      />
    ),
  };
  const faq = (
    <FaqBounty
      title={
        <BlockTitle
          highlightText={<Trans>Как заработать на криптовалютах:</Trans>}
          title={<Trans>частые вопросы</Trans>}
          highlightPosition="start"
          maxWidthTitle={770}
        />
      }
    />
  );
  return (
    <Layout>
      <AppRating />
      <SolutionLayout
        head={head}
        descriptions={descriptions}
        blocks={blocks}
        faq={faq}
      />
      <DownBlock />
    </Layout>
  );
};

export default BountyPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["bountyPage", "translation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
