import React from 'react';
import Faq from '../Faq/Faq';
import { Trans } from 'gatsby-plugin-react-i18next';
import { FaqType } from '../../types/faq.types';

const FAQS: Array<FaqType> = [
  {
    question: <Trans>Как получить криптовалюту бесплатно?</Trans>,
    answer: (
      <>
        <p>
          <Trans>
            Airdrop – это бесплатная раздача токенов после выполнения необходимых условий. Цель такой акции — повысить узнаваемость и привлечь потенциальных инвесторов и новых пользователей к определенной криптовалюте. Условиями получения токенов могут быть регистрация, оформление подписки, совершение сделок на платформе, привлечение знакомых и другие несложные задания. Octo Browser помогает обойти ограничения по использованию нескольких аккаунтов одним участником и увеличить шансы получения токенов.
          </Trans>
        </p>
      </>
    ),
  },
  {
    question: <Trans>Как заработать на попадании в вайтлисты?</Trans>,
    answer: (
      <>
        <p>
          <Trans>
            Вайтлист — это список людей, допущенных к покупке монет на ранней
            стадии развития криптопроекта. Список формируется случайным образом
            из людей выполнивших задания. Попадание в вайтлист даёт возможность
            приобрести токены по низкой стоимости и заработать на разнице цен
            покупки на IDO и ценой продажи на криптобирже. Также, вы можете
            продать аккаунт попавший в вайтлист на внебиржевом рынке, до
            распродажи токенов и гарантировать заработок без вложения своих
            средств.
          </Trans>
        </p>
        <p>
          <Trans>
            Octo Browser позволяет создать множество аккаунтов и
            автоматизировать выполнение условий попадания в вайтлист, умножая
            шансы получения доступа к IDO.
          </Trans>
        </p>
      </>
    ),
  },
  {
    question: <Trans>Что нужно для мультиаккаунтинга в крипте?</Trans>,
    answer: (
      <Trans>
        Мультиаккаунтинг даёт серьёзные преимущества для заработка на рынке
        криптовалют, но этот способ достижения целей связан с рисками
        безвозвратной потери аккаунтов, при отсутствии надёжных инструментов.
        Octo Browser позволяет создать сотни надежных аккаунтов, исключает обмен
        или утечки данных, указывающих сайтам на нарушения и обеспечивает
        анонимность любой активности в сети.
      </Trans>
    ),
  },
];

type FaqBountyProps = {
  title: JSX.Element;
};
const FaqBounty = ({ title }: FaqBountyProps): React.ReactElement => {
  return <Faq isWhite faqs={FAQS} title={title} />;
};

export default FaqBounty;
